html, body {
    height: 100%;
    margin: 0;
    /* overflow: hidden; */
  }
  
  .scroll-container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
    scroll-behavior: smooth;
  }
/*   
  .scroll-section {
    scroll-snap-align: start;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   */